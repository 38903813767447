import $ from 'jquery'

export const customResize = () => {
  let itemGalleryWidth  = $( '.page__content .content__gallery-slide.resize' ).width()
  $( '.page__content .content__gallery-slide.resize' )
    .height(itemGalleryWidth / 1.5)

  let categoryItemWidth   = $( '.block.resize' ).width()
  $( '.block.resize' )
    .height(categoryItemWidth / 1.2695)
  
}
