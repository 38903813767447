import $ from 'jquery'
import alertify from 'alertifyjs'

export const customAlert = () => {

  alertify.set('notifier', 'position', 'bottom-right');
  alertify.set('notifier','delay', 10);
  
  document.addEventListener( 'wpcf7mailsent', ( event ) => {
    alertify.success(event.detail.apiResponse.message)
  }, false );

  document.addEventListener( 'wpcf7invalid', ( event ) => {
    alertify.warning(event.detail.apiResponse.message); 
    
  }, false );

  document.addEventListener( 'wpcf7mailfailed', ( event ) => {
    alertify.error(event.detail.apiResponse.message); 
  }, false );

}