import $ from 'jquery'
import Slideout from 'slideout'

export const customMenu = () => {
  let slideout = new Slideout({
    'panel': document.getElementById('wrapper'),
    'menu': document.getElementById('mmenu'),
    'padding': 320,
    // 'tolerance': 70,
    'side': 'right'
  })

  let toggleButton = document.querySelector('.toggle-menu')
  if(toggleButton !== null) {
    toggleButton.addEventListener('click', (event) => {
      slideout.toggle();
    })

    slideout.on('open',   () => toggleButton.children[0].classList.replace('fa-bars', 'fa-times'))
    slideout.on('close',  () => toggleButton.children[0].classList.replace('fa-times', 'fa-bars'))
  }

  $( window ).resize(() => {
    let windowContainer = 1200
    if( $( window ).width() > windowContainer) {
      slideout.close()
      slideout.disableTouch();
    } else {
      slideout.enableTouch();
    }
  })  
}