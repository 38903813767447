import $ from 'jquery'
import IMask from 'imask';

export const customMask = () => {
  var element = document.querySelector('[type="tel"]')
  var maskOptions = {
    mask: '+{7}(000)000-00-00'
  };
  if(element) {
    var mask = new IMask(element, maskOptions);
  }
}