import $ from 'jquery'
import slick from 'slick-carousel'

export const customSlider = () => {
  $('[data-slider="object_gallery"]').slick({
    rows: 0,
    arrows: true,
    prevArrow: '<button type="button" class="object__gallery-arrow object__gallery-arrow--prev"></button>',
    nextArrow: '<button type="button" class="object__gallery-arrow object__gallery-arrow--next"></button>',
    adaptiveHeight: true,
    lazyLoad: 'progressive',
    mobileFirst: true,
    // swipe: false
  })

  $('[data-slider="objects"]').slick({
    rows: 0,
    arrows: true,
    prevArrow: '<button type="button" class="single__objects-arrow single__objects-arrow--prev"></button>',
    nextArrow: '<button type="button" class="single__objects-arrow single__objects-arrow--next"></button>',
    adaptiveHeight: true,
    lazyLoad: 'progressive',
    mobileFirst: true,
    slidesToShow: 4,
		responsive: [
			{
				breakpoint: 1199,
				settings: {
					// arrows: false
				}
			},
			{
				breakpoint: 992,
				settings: {
					slidesToShow: 3,
					arrows: false
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
					arrows: false
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					arrows: false
				}
			},
			{
				breakpoint: 320,
				settings: {
					slidesToShow: 1,
					arrows: false
				}
			}
		]
  })
}