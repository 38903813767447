import './styles.js'
import $ from 'jquery'

import { customPreloader }  from './_customPreloader'
import { customAnimation }  from './_customAnimation'
import { customMenu }       from './_customMenu'
import { customSlider }     from './_customSlider'
import { customMask }       from './_customMask'
import { customAlert }      from './_customAlert'
import { customResize }      from './_customResize'

import fancybox from '@fancyapps/fancybox'

(function($) {

  $('[data-fancybox]').fancybox({
    image: true
  });

  $( window ).on('load', () => {

    customPreloader()
    customAnimation()
    customMenu()
    customSlider()
    customMask()
    customAlert()
    customResize()

    $('#modal-offer-service input[type="submit"]').click((event) => {
      let $this             = $( event.currentTarget )
      let serviceTitle      = $('.single__title').text()
      let serviceNameInput  = $this.siblings('.name_service').find('input')

      if($this && serviceTitle) {
        serviceNameInput.attr('value', serviceTitle)
      }
    })

    $('#modal-offer input[type="submit"]').click((event) => {
      let $this             = $( event.currentTarget )
      let objectTitle       = $('.page__title').text()
      let objectNameInput   = $this.siblings('.object_name').find('input')

      if($this && objectTitle) {
        objectNameInput.attr('value', objectTitle)
      }
    })

  })

  $( window ).resize(() => {
    customResize()
  })
})(jQuery)

